var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"center-management"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"pa-10 pt-5"},[_c('div',[_c('h2',{staticClass:"d-flex"},[_c('v-icon',{on:{"click":function($event){return _vm.back()}}},[_vm._v("arrow_back_ios")]),_c('div',{staticClass:"d-flex align-center"},[_vm._v("Student Attendance")])],1),_c('div',{staticClass:"success"},[_c('v-alert',{staticStyle:{"font-size":"14px","padding":"12px"},attrs:{"value":_vm.checkSuccess,"transition":"scale-transition","type":"success"}},[_vm._v(" Update attendance successfully! ")])],1),_c('h3',{staticClass:"pa-5"},[_vm._v(" Attendance for ("+_vm._s(_vm.classCode)+") from "+_vm._s(_vm.classTime)+" on "+_vm._s(_vm.dayOfWeek)+", "+_vm._s(_vm.date)+" ")])]),_c('v-divider'),_c('div',{staticClass:"pt-5 pb-2"},[(_vm.students.length != 1)?_c('span',[_vm._v("Total: "+_vm._s(_vm.students.length)+" students")]):_c('span',[_vm._v("Total: "+_vm._s(_vm.students.length)+" student")])]),_c('div',{staticClass:"attendance-button mb-2",on:{"click":function($event){return _vm.attendanceAll()}}},[_vm._v("Attendance all")]),_c('div',{staticClass:"pb-10"},[(_vm.isLoading)?_c('v-progress-linear',{staticClass:"my-10",attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),(!_vm.isLoading)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.students,"items-per-page":100,"hide-default-footer":"","fixed-header":""},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"60"}},[(!item.photo)?_c('img',{staticClass:"pa-2",attrs:{"src":require("@/assets/images/ImageProfile.jpg")}}):_c('img',{attrs:{"src":item.photo}})])]}},{key:"item.attendanceStatus",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"hide-details":"","row":""},model:{value:(item.attendanceStatus),callback:function ($$v) {_vm.$set(item, "attendanceStatus", $$v)},expression:"item.attendanceStatus"}},[_c('v-radio',{attrs:{"on-icon":"mdi-checkbox-marked-circle","color":"secondary","label":"Attendance","value":"Attended"}}),_c('v-radio',{attrs:{"on-icon":"mdi-checkbox-marked-circle","color":"secondary","label":"Absent","value":"Absent"}})],1)]}}],null,true)}):_vm._e()],1)],1)])],1),_c('v-footer',{staticClass:"pl-8 pr-8",attrs:{"fixed":"","absolute":"","height":"60","color":"#fff"}},[_c('v-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.dialog = true
              _vm.save()}}},[_vm._v("Save")])],1)])])],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"mx-auto pa-10"},[_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"pl-4 pr-4 mr-3 mb-5",staticStyle:{"background":"white"},attrs:{"elevation":"0","height":"36","loading":_vm.dialog}})],1),_c('div',{staticClass:"text-center"},[_c('b',{staticStyle:{"font-size":"1.5rem"}},[_vm._v("Saving!")]),_c('br'),_c('span',[_vm._v("Please wait...")])])])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","width":"350"},model:{value:(_vm.errorDialog),callback:function ($$v) {_vm.errorDialog=$$v},expression:"errorDialog"}},[_c('v-card',{staticClass:"mx-auto pa-6"},[_c('div',{staticClass:"text-center"},[_c('h1',[_vm._v("Error!")]),_c('p',[_vm._v("This class has no students!")])]),_c('div',{staticClass:"text-center pt-4"},[_c('v-btn',{attrs:{"color":"error","elevation":"0","outlined":""},on:{"click":function($event){return _vm.back()}}},[_vm._v(" Back ")])],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }